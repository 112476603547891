import * as ReducerActions from "../../actions/globalSettings/GS_generalSettings";

const initialState = {
    mainContactPageVisible: false,
    offline: false,
    offlineMessage: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GS_UPDATE_MAINCONTACTPAGE:
            return { ...state, mainContactPageVisible: action.value };
        case ReducerActions.GS_UPDATE_SITE_OFFLINE:
            return { ...state, offline: action.value };
        case ReducerActions.GS_UPDATE_SITE_OFFLINE_MESSAGE:
            return { ...state, offlineMessage: action.value };
        default:
            return state;
    }
};

export default reducer;
