import React from "react";
import useWindowSize from "../../../pods/wholeSite/windowSize";
import CenterContainer from "../../../pods/wholeSite/centerContainer";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import EnvironmentPodsMap from "../../../../environment/pods/environmentPodsMap";

//STYLES
import * as adminLoginStyles from "../styles/login";

function AdminLogin() {
    const [useWindowHeight] = useWindowSize();
    const navigate = useNavigate();

    function forgotPassword() {
        navigate("../forgotPassword");
    }

    return (
        <div>
            <Row style={adminLoginStyles.body} className="align-items-center">
                <Col>
                    <Row>
                        <Col className="text-center">
                            <CenterContainer>
                                <EnvironmentPodsMap podCode="loginForm" />
                            </CenterContainer>
                        </Col>
                    </Row>
                </Col>
                <Col style={adminLoginStyles.forgotPassword}>
                    <i style={adminLoginStyles.helpIcon} className="fa-solid fa-circle-question" onClick={forgotPassword}></i>
                </Col>
            </Row>
        </div>
    );
}

export default AdminLogin;
