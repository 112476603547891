export const USER_UPDATE_AUTH = "USER_UPDATE_AUTH";
export const USER_UPDATE_ADMINSIGNEDIN = "USER_UPDATE_ADMINSIGNEDIN";
export const USER_UPDATE_ID = "USER_UPDATE_ID";
export const USER_UPDATE_NAME = "USER_UPDATE_NAME";
export const USER_UPDATE_EMAIL = "USER_UPDATE_EMAIL";
export const USER_UPDATE_ADDRESS = "USER_UPDATE_ADDRESS";
export const USER_UPDATE_TYPE = "USER_UPDATE_TYPE";
export const USER_UPDATE_ISLOADED = "USER_UPDATE_ISLOADED";
export const USER_UPDATE_NEW = "USER_UPDATE_NEW";
export const USER_UPDATE_REQUESTEDPASSWORD = "USER_UPDATE_REQUESTEDPASSWORD";

export const UpdateAuth = (val) => {
	return {
		type: USER_UPDATE_AUTH,
		value: val,
	};
};

export const UpdateAdminSignedIn = (val) => {
	return {
		type: USER_UPDATE_ADMINSIGNEDIN,
		value: val,
	};
};

export const UpdateID = (val) => {
	return {
		type: USER_UPDATE_ID,
		value: val,
	};
};

export const UpdateName = (val) => {
	return {
		type: USER_UPDATE_NAME,
		value: val,
	};
};

export const UpdateEmail = (val) => {
	return {
		type: USER_UPDATE_EMAIL,
		value: val,
	};
};

export const UpdateAddress = (val) => {
	return {
		type: USER_UPDATE_ADDRESS,
		value: val,
	};
};

export const UpdateType = (val) => {
	return {
		type: USER_UPDATE_TYPE,
		value: val,
	};
};

export const UpdateIsLoaded = (val) => {
	return {
		type: USER_UPDATE_ISLOADED,
		value: val,
	};
};

export const UpdateNew = (val) => {
	return {
		type: USER_UPDATE_NEW,
		value: val,
	};
};

export const UpdateRequestedPassword = (val) => {
	return {
		type: USER_UPDATE_REQUESTEDPASSWORD,
		value: val,
	};
};
