import * as ReducerActions from "../../actions/globalSettings/GS_pageSettings";

const initialState = {
  layout: [],
  editorials: [],
  showEditorials: false,
  insert: { rowID: 0, columnID: 0 },
  deletedColumns: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ReducerActions.GS_PAGESETTINGS_UPDATE_LAYOUT:
      return { ...state, layout: action.value };
    case ReducerActions.GS_PAGESETTINGS_UPDATE_SHOWEDITORIALS:
      return { ...state, showEditorials: action.value };
    case ReducerActions.GS_PAGESETTINGS_UPDATE_EDITORIALS:
      return { ...state, editorials: action.value };
    case ReducerActions.GS_PAGESETTINGS_UPDATE_EDITORIALINSERT:
      return { ...state, insert: action.value };
    case ReducerActions.GS_PAGESETTINGS_UPDATE_INSERTDELETED:
      return {
        ...state,
        deletedColumns: [...state.deletedColumns, action.value],
      };
    default:
      return state;
  }
};

export default reducer;
