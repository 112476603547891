import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const addUserButton = {
    textAlign: "right",
    paddingTop: "20px",
    paddingBottom: "20px"
};

export const doubleClickMessage = {
    fontSize: "1.5rem",
    fontWeight: "bold"
};

export const rolesTabs = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    cursor: "pointer"
};

export const rolesTabs_selected = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    borderBottom: "solid 4px",
    cursor: "pointer"
};
