import * as UserActions from "../actions/user";

const initialState = {
	auth: false,
	adminSignedIn: false,
	id: "",
	fullName: "",
	email: "",
	address: "",
	phone: "",
	type: "",
	isLoaded: false,
	new: false,
	requestedPassword: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UserActions.USER_UPDATE_AUTH:
			return { ...state, auth: action.value };
		case UserActions.USER_UPDATE_ADMINSIGNEDIN:
			return { ...state, adminSignedIn: action.value };
		case UserActions.USER_UPDATE_ID:
			return { ...state, id: action.value };
		case UserActions.USER_UPDATE_NAME:
			return { ...state, fullName: action.value };
		case UserActions.USER_UPDATE_EMAIL:
			return { ...state, email: action.value };
		case UserActions.USER_UPDATE_ADDRESS:
			return { ...state, address: action.value };
		case UserActions.USER_UPDATE_TYPE:
			return { ...state, type: action.value };
		case UserActions.USER_UPDATE_ISLOADED:
			return { ...state, isLoaded: action.value };
		case UserActions.USER_UPDATE_NEW:
			return { ...state, new: action.value };
		case UserActions.USER_UPDATE_REQUESTEDPASSWORD:
			return { ...state, requestedPassword: action.value };
		default:
			return state;
	}
};

export default reducer;
