import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import Features_Intro from "./files/features";
import FeatureArea from "./files/featureArea";
import EnvironmentPodsMap from "../../../environment/pods/environmentPodsMap";

function PodRouter() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div>
      {isLoaded && (
        <div>
          <Routes>
            <Route path="/" element={<Features_Intro />} />
            <Route path="/pod/:code/*" element={<FeatureArea />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default PodRouter;
