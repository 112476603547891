import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const cardStyle = {
  backgroundColor: Colors.primary,
  color: "white",
  cursor: "pointer",
  padding: "10px",
};

export const headingCardStyle = {
  backgroundColor: Colors.primary,
  color: "gold",
  fontWeight: "bold",
  padding: "10px",
};

export const removeBtnCardStyle = {
  backgroundColor: "red",
  color: "white",
  fontWeight: "bold",
  padding: "10px",
  cursor: "pointer",
  textAlign: "center",
};
