export const borders = {
	border: "solid 2px",
};

export const textSize = {
	fontSize: "1rem",
};

export const filesTableRow = {
	padding: "5px",
};

export const filesTableheading = {
	color: "red",
};

export const errorModalColor = {
	color: "red",
};

export const successModalColor = {
	color: "green",
};
