import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import PageControl from "./files/pages";
import PageSettings from "./files/pageSettings";

function PodRouter() {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div>
			{isLoaded && (
				<div>
					<Routes>
						<Route path="/" element={<PageControl />} />
						<Route path="/settings/:id" element={<PageSettings />} />
					</Routes>
				</div>
			)}
		</div>
	);
}

export default PodRouter;
