import React, { useState } from "react";
import useWindowSize from "../../../pods/wholeSite/windowSize";
import CenterContainer from "../../../pods/wholeSite/centerContainer";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//STYLES
import * as adminLoginStyles from "../styles/login";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

function ForgotPassword() {
	const [useWindowHeight] = useWindowSize();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");

	const [modal, setModal] = useState({
		header: "",
		open: false,
		message: "",
		error: false,
	});

	function handleCloseModal() {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});
	}

	function handleOnChange(event) {
		const { value } = event.target;

		setEmail(value);
	}

	function handleGetVerificationCode() {
		if (email === "") {
			setModal({ header: "Forgot Password", message: "Please enter an email!", error: true, open: true });
		} else {
			const data = { email: email };

			Axios.post("/adminPods/security/getVerificationCode", data)
				.then((res) => {
					const data = res.data;
					if (data.error === "Yes") {
						setModal({ header: "Forgot Password", message: data.message, error: true, open: true });
					} else {
						navigate(`../validation/${data.token}`);
					}
				})
				.catch((err) => console.log(err));
		}
	}

	return (
		<div>
			<Row style={{ ...adminLoginStyles.body, height: useWindowHeight - 10 }} className="align-items-center">
				<Col>
					<Row>
						<Col className="text-center">
							<CenterContainer>
								<CenterContainer style={adminLoginStyles.innerContainer}>
									<h1>Forgot Password?</h1>
									<br />
									To verity it is you, please enter your email below to recieve a code.
									<Form.Control type="text" value={email} onChange={handleOnChange} />
									<Button variant="warning" style={adminLoginStyles.loginBtns} onClick={handleGetVerificationCode}>
										Get Verification Code
									</Button>
								</CenterContainer>
							</CenterContainer>
						</Col>
					</Row>
				</Col>
			</Row>
			<Modal show={modal.open} onHide={handleCloseModal}>
				<Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
					<Modal.Title>{modal.header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modal.message}</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleCloseModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default ForgotPassword;
