import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";

import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ContentStyles from "../styles/editorials";

//import * as StyleTest from "../../../framework/highViewStudios/styles/styleTest";

function Admin_Editorials(props) {
  let StyleTest = {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editorials, setEditorials] = useState([]);

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("Editorials"));
    dispatch(GS_navSettingsActions.UpdateSelected("Editorials"));
    onOpen();
  }, []);

  function onOpen() {
    Axios.post("/adminPods/editorials/getAllEditorials")
      .then((res) => {
        const data = res.data;
        console.log(data);
        setEditorials(data.editorials);
        dispatch(GS_navSettingsActions.UpdateSizing(3));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNewItemOnClick() {
    dispatch(GS_navSettingsActions.UpdateSizing(1));
    navigate("./create");
  }

  function handleClickOnEditorial(uuid) {
    navigate(`./modify/${uuid}`);
  }
  return (
    <div style={ContentStyles.body}>
      <Button onClick={handleNewItemOnClick}>New Item</Button>
      <br />
      <div style={ContentStyles.editorialContainer}>
        {editorials.map((item, index) => {
          return (
            <div key={index}>
              <Card
                style={ContentStyles.cardStyle}
                onClick={handleClickOnEditorial.bind(this, item.uuid)}
              >
                <Card.Body>
                  <Row>
                    <Col>
                      <strong style={ContentStyles.cardHeadings}>Title:</strong>{" "}
                      {item.title}
                    </Col>
                    <Col>
                      <strong style={ContentStyles.cardHeadings}>
                        Date Created:
                      </strong>{" "}
                      {item.dateCreated}
                    </Col>
                    <Col>
                      <strong style={ContentStyles.cardHeadings}>
                        Date Modified:
                      </strong>{" "}
                      {item.dateModified}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Admin_Editorials;
