import React, { useState, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

//STYLES
import * as PageSettingsStyles from "../styles/pageSettings";

//ACTIONS
import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";

function ItemsViewer(props) {
    const dispatch = useDispatch();

    const [noItems, setNoItems] = useState(false);
    const [menuView, setMenuView] = useState({
        title: "",
        backBtn: false,
        items: []
    });
    const [menuHistory, setMenuHistory] = useState([]);

    useEffect(() => {
        onStart();
    }, []);

    function onStart() {
        Axios.post("/adminPods/pages/itemsViewer/onLoad")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (!data.editorialsMenu && !data.podsMenu) {
                    setMenuView({ title: "No Items", items: [] });
                    setNoItems(true);
                } else {
                    const items = [];
                    if (data.editorialsMenu) {
                        items.push({
                            title: "Insert an Editorial",
                            method: openEditorials
                        });
                    }
                    if (data.podsMenu) {
                        items.push({
                            title: "Insert a Pod",
                            method: openPodMenu
                        });
                    }
                    const menu = {
                        title: "Add an Item",
                        backBtn: false,
                        items: items
                    };
                    setMenuHistory((prevState) => {
                        return [...prevState, menu];
                    });
                    setMenuView(menu);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleGoBackMenu() {
        setMenuView(menuHistory[menuHistory.length - 2]);
        menuHistory.pop();
    }

    function openEditorials() {
        Axios.post("/adminPods/pages/itemsViewer/getEditorials")
            .then((res) => {
                const data = res.data;
                const items = [];

                data.editorials.map((item) => {
                    item.method = handleSetEditorialInSlot.bind(this, item.uuid, item.title);
                    items.push(item);
                });
                const menu = {
                    title: "Add an Editorial",
                    backBtn: true,
                    items: items
                };
                setMenuHistory((prevState) => {
                    return [...prevState, menu];
                });
                setMenuView(menu);
            })
            .catch((err) => console.log(err));
    }

    function openPodMenu() {
        Axios.post("/adminPods/pages/itemsViewer/getPods")
            .then((res) => {
                const data = res.data;
                const items = [];
                console.log(data);

                if (data.contactsPod) {
                    items.push({
                        title: "Contacts",
                        method: openContactsMenu,
                        podWidth: 3
                    });
                }

                data.features.map((item) => {
                    let title = "";
                    if (item.subTitle != "") {
                        title = `${item.title} - ${item.subTitle}`;
                    } else {
                        title = item.title;
                    }
                    item.title = title;
                    item.method = handleSetPodInSlot.bind(this, item);
                    items.push(item);
                });
                const menu = {
                    title: "Insert a Pod",
                    backBtn: true,
                    items: items
                };
                setMenuHistory((prevState) => {
                    return [...prevState, menu];
                });
                setMenuView(menu);
            })
            .catch((err) => console.log(err));
    }

    function openContactsMenu(editorials, pods) {
        Axios.post("/adminPods/pages/itemsViewer/getContacts")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    const items = [];

                    data.contacts.map((contact) => {
                        items.push({
                            title: contact.name,
                            method: handleSetContactInSlot.bind(this, contact.userID, contact.name)
                        });
                    });
                    const menu = {
                        title: "Insert a Contact",
                        backBtn: true,
                        items: items
                    };
                    setMenuHistory((prevState) => {
                        return [...prevState, menu];
                    });
                    setMenuView(menu);
                }
            })
            .catch((err) => console.log(err));
    }

    function openSegmentMenu(pod) {
        Axios.post(`/pods/${pod.adminCode}/itemsViewer_loadSegments`)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const items = [];

                    data.menu.map((segment) => {
                        const item = { title: segment.title, method: handleSetSegmentItemInSlot.bind(this, pod, segment) };

                        items.push(item);
                    });

                    const menu = {
                        title: pod.title,
                        backBtn: true,
                        items: items
                    };
                    setMenuHistory((prevState) => {
                        return [...prevState, menu];
                    });
                    setMenuView(menu);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSetEditorialInSlot(id, title) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(props.rowID, props.colID, `Editorials - ${title}`, "ADMIN_EDITORIALS", id));

        props.closeBtn();
    }

    function handleSetContactInSlot(id, fullName) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(props.rowID, props.colID, `Contact - ${fullName}`, "ADMIN_CONTACTS", id));
        props.closeBtn();
    }

    function handleSetPodInSlot(item) {
        if (item.segmented == "true") {
            openSegmentMenu(item);
        } else {
            dispatch(GS_pageSettingsActions.SetPodToLayout(props.rowID, props.colID, CapsFirstLetter(item.title), item.frontEndCode, ""));
            props.closeBtn();
        }
    }

    function handleSetSegmentItemInSlot(pod, segment) {
        dispatch(GS_pageSettingsActions.SetPodToLayout(props.rowID, props.colID, `${pod.title} - ${segment.title}`, pod.frontEndCode, segment.uuid));
        props.closeBtn();
    }

    function handleShowPodWidthError() {}

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div>
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>{menuView.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {noItems ? (
                        <div>
                            There are no items to add on your page. Please add Editorials or Pods. <br />
                            <br />
                            <strong>Please contact High-View Studios to request Pods</strong>
                        </div>
                    ) : (
                        <div>
                            {menuView.backBtn && (
                                <div>
                                    <Card style={PageSettingsStyles.backBtnStyle} onClick={handleGoBackMenu}>
                                        <Card.Body>Back</Card.Body>
                                    </Card>
                                    <br />
                                </div>
                            )}

                            {menuView.items.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {menuView.title == "Insert a Pod" ? (
                                            <div>
                                                {item.podWidth <= props.colWidth ? (
                                                    <div>
                                                        <Card style={PageSettingsStyles.cardStyle} onClick={item.method}>
                                                            <Card.Body>{item.title}</Card.Body>
                                                        </Card>
                                                        <br />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Card style={PageSettingsStyles.cardRedStyle}>
                                                            <Card.Body>
                                                                {item.title} <br />
                                                                This pod is for a wider column
                                                            </Card.Body>
                                                        </Card>
                                                        <br />
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <Card style={PageSettingsStyles.cardStyle} onClick={item.method}>
                                                    <Card.Body>{item.title}</Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.closeBtn}>
                        Close
                    </Button>
                </Modal.Footer>
            </div>
        </div>
    );
}

export default ItemsViewer;
