import React from "react";
import { useSelector } from "react-redux";

//STYLES
import * as SiteOfflineStyles from "../styles/siteOffline";

function SiteOffline() {
    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);

    return (
        <div style={SiteOfflineStyles.body}>
            <h1>
                <strong>Site Offline</strong>
            </h1>
            <br />
            {GS_generalSettings.offlineMessage == "" ? (
                <div>
                    <h2>This site is down for maintenance</h2>
                </div>
            ) : (
                <div>
                    <h2>{GS_generalSettings.offlineMessage}</h2>
                </div>
            )}
        </div>
    );
}

export default SiteOffline;
