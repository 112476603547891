import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import path from "path";

import * as MediaActions from "../../../store/actions/media";

import Media_Card from "./card";

function MediaCanvas() {
    useEffect(() => {
        getDirectoryFiles(media.currentURI);
    }, []);
    const dispatch = useDispatch();
    const media = useSelector((state) => state.media);

    function getDirectoryFiles(dirPath) {
        const data = { directory: dirPath };

        Axios.post("/adminPods/fileSystem/getDirectoryFiles", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    //NEW CHANGE
                    dispatch(MediaActions.UpdateFiles(data.files.entries, data.files.empty, data.files.subDir));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            {media.empty ? (
                <div>
                    {media.subDir ? (
                        <div>
                            <Row>
                                <Col>
                                    <Media_Card editable={true} show={true} up={true} />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>You haven&#39;t got any images uploaded yet, go to the media section to upload some</div>
                    )}
                </div>
            ) : (
                <div>
                    {media.files.map((file, index) => {
                        return (
                            <Row key={index}>
                                <Col>
                                    <Media_Card
                                        editable={false}
                                        show={true}
                                        up={file.type == "GoUp"}
                                        directory={file.type == "Directory"}
                                        url={`/${path.join(media.currentURI, file.name)}`}
                                        text={file.name}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default MediaCanvas;
