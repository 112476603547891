import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Switch from "react-switch";
import Axios from "axios";

function MoreSettings(props) {
  const [settings, setSettings] = useState({
    address: true,
    telephone: true,
    nameVis: false,
    emailVis: false,
    addressVis: false,
    telephoneVis: false,
    detailsNotice: true,
  });

  useEffect(() => {
    const data = { id: props.userID };
    Axios.post("/adminPods/contacts/getMoreSettings", data)
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (data.error == "null") {
          setSettings((prevState) => {
            return {
              ...prevState,
              address: data.details.address == "true",
              telephone: data.details.telephone == "true",
              nameVis: data.visibles.nameVis == "true",
              emailVis: data.visibles.emailVis == "true",
              addressVis: data.visibles.addressVis == "true",
              telephoneVis: data.visibles.telephoneVis == "true",
              detailsNotice:
                data.details.address != "true" ||
                data.details.telephone != "true",
            };
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function changeNameVisibilty(checked) {
    setSettings((prevState) => {
      return { ...prevState, nameVis: checked };
    });
  }

  function changeEmailVisibilty(checked) {
    setSettings((prevState) => {
      return { ...prevState, emailVis: checked };
    });
  }

  function changeAddressVisibilty(checked) {
    setSettings((prevState) => {
      return { ...prevState, addressVis: checked };
    });
  }

  function changeTelephoneVisibilty(checked) {
    setSettings((prevState) => {
      return { ...prevState, telephoneVis: checked };
    });
  }

  function handleSaveBotton() {
    const data = {
      id: props.userID,
      nameVis: settings.nameVis.toString(),
      emailVis: settings.emailVis.toString(),
      addressVis: settings.addressVis.toString(),
      telephoneVis: settings.telephoneVis.toString(),
    };

    Axios.post("/adminPods/contacts/saveMoreSettings", data)
      .then((res) => {
        const data = res.data;
        if (data.error == "null") {
          props.closeBtn();
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>More Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Select the details you want to show as well as the contact form</p>
        <Row>
          <Col>Detail</Col>
          <Col>Visible</Col>
          <Col md={6}></Col>
        </Row>
        <br />
        <Row>
          <Col>Name:</Col>
          <Col>
            <Switch
              className="customCSS-Content-New-Item-Switch"
              onChange={changeNameVisibilty}
              checked={settings.nameVis}
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <br />
            <br />
          </Col>
          <Col md={6}></Col>
        </Row>
        <Row>
          <Col>Email Address:</Col>
          <Col>
            <Switch
              className="customCSS-Content-New-Item-Switch"
              onChange={changeEmailVisibilty}
              checked={settings.emailVis}
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <br />
            <br />
          </Col>
          <Col md={6}></Col>
        </Row>
        <Row>
          <Col>Address:</Col>
          <Col>
            <Switch
              className="customCSS-Content-New-Item-Switch"
              onChange={changeAddressVisibilty}
              checked={settings.addressVis}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </Col>
          <br />
          <br />
          <Col md={6}>{settings.address == "" && "No data is enetered"}</Col>
        </Row>
        <Row>
          <Col>Telephone:</Col>
          <Col>
            <Switch
              className="customCSS-Content-New-Item-Switch"
              onChange={changeTelephoneVisibilty}
              checked={settings.telephoneVis}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </Col>
          <br />
          <br />
          <Col md={6}>{settings.telephone == "" && "No data is enetered"}</Col>
        </Row>
        {settings.detailsNotice && (
          <strong>
            Some details are not entered. You can enter these details in the
            users section.
          </strong>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.closeBtn}>Close</Button>
        <Button onClick={handleSaveBotton}>Save</Button>
      </Modal.Footer>
    </div>
  );
}

export default MoreSettings;
