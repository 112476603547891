import React, { useState } from "react";
import Axios from "axios";

function PassAccess() {
	const [message, setMessage] = useState("");
	const [colour, setColour] = useState("back");

	function handleOnClick(event) {
		event.preventDefault();
		const key = document.getElementById("key").value;
		const password = document.getElementById("password").value;
		const confirmPassword = document.getElementById("confirmPassword").value;

		const data = { key: key, password: password, confirmPassword: confirmPassword };
		Axios.post("/adminPods/admin/changeAccess", data)
			.then((res) => {
				const data = res.data;
				if (data.error === "Yes") {
					setColour("red");
					setMessage("Error: " + data.message);
				} else {
					setColour("green");
					setMessage(data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<div style={{ backgroundColor: "white", marginLeft: "10px", fontSize: "18px" }}>
			<strong>Change Password:</strong> <br /> <br />
			<div style={{ color: colour, fontWeight: "bold" }}>{message}</div>
			<form>
				Pass Key: <br />
				<input type="password" id="key" />
				<br /> <br />
				New Password: <br />
				<input type="password" id="password" />
				<br /> <br />
				Confirm New Password: <br />
				<input type="password" id="confirmPassword" />
				<br /> <br />
				<button onClick={handleOnClick}>Submit</button>
			</form>
		</div>
	);
}

export default PassAccess;
