import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import * as UsersControlStyles from "../styles/usersControlStyles";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

function UsersControl() {
    const [users, setUsers] = useState([]);
    const [tab, setTab] = useState("admin&Modified");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        getUsers(tab);
        dispatch(GS_navSettingsActions.UpdateTitle("Users"));
        dispatch(GS_navSettingsActions.UpdateSelected("Users"));
    }, []);

    function getUsers(selectedTab) {
        const data = { type: user.type, tab: selectedTab };

        Axios.post("/adminPods/users/getUsers", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setUsers(data.users);
                }
            })
            .catch((err) => console.log(err));
    }

    function userDoubleClick(id) {
        navigate(`./modify/${id}`);
    }

    function formatType(type) {
        if (type == "seniorAdmin") {
            return "Senior Admin";
        } else {
            return type.charAt(0).toUpperCase() + type.slice(1);
        }
    }

    function handleAddNewUser() {
        navigate("./create");
    }

    function changeTab(tab) {
        setTab(tab);
        getUsers(tab);
    }

    return (
        <div style={UsersControlStyles.body}>
            <Row>
                <Col style={UsersControlStyles.addUserButton}>
                    <Button onClick={handleAddNewUser}>
                        <i className="fa-solid fa-plus"></i>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col
                    style={tab == "admin&Modified" ? UsersControlStyles.rolesTabs_selected : UsersControlStyles.rolesTabs}
                    onClick={changeTab.bind(this, "admin&Modified")}
                >
                    Admin and Modified
                </Col>
                <Col
                    style={tab == "registered" ? UsersControlStyles.rolesTabs_selected : UsersControlStyles.rolesTabs}
                    onClick={changeTab.bind(this, "registered")}
                >
                    Registered
                </Col>
            </Row>
            <br />
            <p style={UsersControlStyles.doubleClickMessage}>Double click on a user to edit their account</p>
            {/* <br /> */}
            <Row>
                <Col>
                    {users.map((user, index) => {
                        return (
                            <div key={index}>
                                <Card style={UsersControlStyles.cardStyle} onDoubleClick={userDoubleClick.bind(this, user.id)}>
                                    <Card.Body>
                                        <Row>
                                            <Col>Name: {user.fullName}</Col>
                                            <Col>Email: {user.email}</Col>
                                            <Col>Type: {formatType(user.type)}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default UsersControl;
