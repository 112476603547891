import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import path from "path";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

import * as MediaActions from "../../../store/actions/media";

//STYLES
import * as CardStyles from "./styles/card";

//IMAGE
import dirIcon from "../../../public/images/DirectoryIcon.png";
import goUpIcon from "../../../public/images/goUp.png";

function Media_Directory(props) {
    const [over, setOver] = useState(false);
    const media = useSelector((state) => state.media);
    const dispatch = useDispatch();

    function handleOnMoveOver() {
        setOver(true);
    }

    function handleOnMoveLeave() {
        setOver(false);
    }

    function truncateString(str, size) {
        return str.length > size ? str.substr(0, size - 1) + "..." : str;
    }

    function handleDirectoryClick(directory) {
        const newPath = path.join(media.move_filePath, directory);
        dispatch(MediaActions.Update_Move_FilePath(newPath));
        getDirectories(newPath);
    }

    function getDirectories(filePath) {
        const data = { directory: filePath };

        Axios.post("/adminPods/fileSystem/getDirectories", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                dispatch(MediaActions.Update_Move_Directories(data.directories));
                dispatch(MediaActions.Update_Move_CardAndRowTotals(data.directories.length));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleDirectoryUpClick() {
        const newPath = path.join(media.move_filePath, "..");
        dispatch(MediaActions.Update_Move_FilePath(newPath));
        getDirectories(newPath);
    }

    return (
        <div onMouseOver={handleOnMoveOver} onMouseLeave={handleOnMoveLeave}>
            {props.show ? (
                <div>
                    {props.up ? (
                        //GoUp Icon
                        <div>
                            <Row>
                                <Col style={CardStyles.directoryTop} onClick={handleDirectoryUpClick}></Col>
                            </Row>
                            <Row>
                                <Col style={CardStyles.dirImgContainer} onClick={handleDirectoryUpClick}>
                                    <Image style={CardStyles.dirPic} src={goUpIcon} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={CardStyles.dirTextContainer} onClick={handleDirectoryUpClick}></Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            <Row>
                                <Col style={CardStyles.directoryTop} onClick={handleDirectoryClick.bind(this, props.text)}></Col>
                            </Row>
                            <Row>
                                <Col style={CardStyles.dirImgContainer} onClick={handleDirectoryClick.bind(this, props.text)}>
                                    <Image style={CardStyles.dirPic} src={dirIcon} />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={!over ? CardStyles.dirTextContainer : CardStyles.dirTextContainerUnderlined}
                                    onClick={handleDirectoryClick.bind(this, props.text)}
                                >
                                    {truncateString(props.text, 20)}
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <Row>
                        <Col style={CardStyles.emptyTopContainer}></Col>
                    </Row>
                    <Row>
                        <Col style={CardStyles.emptyPicContainer}></Col>
                    </Row>
                    <Row>
                        <Col style={CardStyles.emptyBtnContainer}></Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default Media_Directory;
