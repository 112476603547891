export const body = {
  paddingRight: "30px",
  paddingTop: "30px",
};

export const addBtn = {
  textAlign: "right",
};

export const removeBtn = {
  textAlign: "right",
};
