import React, { useState, useEffect } from "react";
import useWindowSize from "../../../pods/wholeSite/windowSize";
import CenterContainer from "../../../pods/wholeSite/centerContainer";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

//STYLES
import * as adminLoginStyles from "../styles/login";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

function ForgotPassword() {
	const [useWindowHeight] = useWindowSize();
	const navigate = useNavigate();
	const params = useParams();
	const [code, setCode] = useState("");
	const [timer, setTimer] = useState(59);
	const [runTimer, setRunTimer] = useState(true);

	useEffect(() => {
		onOpen();
	}, []);

	//TIMER
	useEffect(() => {
		if (runTimer) {
			setTimeout(() => {
				if (timer === 1) {
					setRunTimer(false);
				} else {
					setTimer(timer - 1);
				}
			}, 1000);
		}
	}, [timer]);

	function onOpen() {
		const data = { token: params.token };

		Axios.post("/adminPods/security/checkValidToken", data)
			.then((res) => {
				const data = res.data;
				if (data.error == "Yes") {
					setModal({ header: "Invalid Token", message: data.message, error: true, closeFunction: handleCloseModalAndBack_Login, open: true });
				}
			})
			.catch((err) => console.log(err));
	}

	const [modal, setModal] = useState({
		header: "",
		open: false,
		message: "",
		error: false,
		closeFunction: "",
	});

	function handleCloseModal() {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});
	}

	function handleCloseModalAndBack_Login() {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});

		navigate("../login");
	}

	function handleCloseModalAndBack_ForgotPassword() {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});

		navigate("../forgotPassword");
	}

	function handleCloseModalAndBack_Token(token) {
		setModal((prevState) => {
			return { ...prevState, open: false };
		});

		navigate(`../validation/${token}`);
	}

	function handleOnChange(event) {
		const { value } = event.target;

		setCode(value);
	}

	function handleCheckValidation() {
		if (code === "") {
			setModal({ header: "Validation", message: "Please enter the code to validate account", error: true, closeFunction: handleCloseModal, open: true });
		} else {
			const data = { code: code, token: params.token };

			Axios.post("/adminPods/security/checkValidationOnToken", data)
				.then((res) => {
					const data = res.data;
					if (data.error === "Yes") {
						if (data.type === "invalidToken") {
							setModal({ header: "Validation", message: data.message, error: true, closeFunction: handleCloseModalAndBack_Login, open: true });
						}
						if (data.type === "invalidCode") {
							setModal({ header: "Validation", message: data.message, error: true, closeFunction: handleCloseModal, open: true });
						}
						if (data.type === "expiredCode") {
							setModal({ header: "Validation", message: data.message, error: true, closeFunction: handleCloseModalAndBack_ForgotPassword, open: true });
						}
					} else {
						const message = "You have been sent an email containing your temporary password.";
						setModal({ header: "Validation", message: message, error: false, closeFunction: handleCloseModalAndBack_Login, open: true });
					}
				})
				.catch((err) => console.log(err));
		}
	}

	function handleResendCode() {
		const data = { token: params.token };

		Axios.post("/adminPods/security/requestNewCode", data)
			.then((res) => {
				const data = res.data;
				if (data.error === "null") {
					setModal({ header: "Validation", message: "New token sent!", error: false, closeFunction: handleCloseModalAndBack_Token.bind(this, data.token), open: true });
				}
			})
			.catch((err) => console.log(err));
	}

	return (
		<div>
			<Row style={{ ...adminLoginStyles.body, height: useWindowHeight - 10 }} className="align-items-center">
				<Col>
					<Row>
						<Col className="text-center">
							<CenterContainer>
								<CenterContainer style={adminLoginStyles.innerContainer}>
									<h1>Enter Code</h1>
									<br />
									You have been sent an email with a code, please enter the code below within 10 minutes
									<Form.Control type="text" value={code} onChange={handleOnChange} />
									<Button variant="warning" style={adminLoginStyles.loginBtns} onClick={handleCheckValidation}>
										Validate Code
									</Button>
									<br /> <br />
									<Button variant="warning" style={runTimer ? adminLoginStyles.loginBtnsHidden : adminLoginStyles.loginBtns} onClick={handleResendCode}>
										Send New Code
									</Button>
									<div style={!runTimer ? adminLoginStyles.hidden : adminLoginStyles.visibile}> Resend code in {timer}</div>
								</CenterContainer>
							</CenterContainer>
						</Col>
					</Row>
				</Col>
			</Row>
			<Modal show={modal.open} onHide={modal.closeFunction}>
				<Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
					<Modal.Title>{modal.header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modal.message}</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={modal.closeFunction}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default ForgotPassword;
