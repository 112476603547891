export const body = {
  margin: "20px",
};

export const borders = {
  border: "solid 1px",
};

export const createDirBtn = {
  textAlign: "right",
};

export const noContentMessage = {
  textAlign: "center",
};

export const urlHeading = {
  fontWeight: "bold",
  paddingTop: "10px",
};

export const errorMessage = {
  color: "red",
  textAlign: "center",
  fontWeight: "bold",
};
