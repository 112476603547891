import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

import * as UserActions from "../../store/actions/user";

//STYLES
import * as AdminHomeStyles from "./styles/admin_home";

//COMPONENTS

import Admin_NavBar from "../pods/navBar/navBar";

import Admin_Overview from "../pods/overview/overview";
import Admin_Users from "../pods/users/podRouter";
import Admin_Pages from "../pods/pages/podRouter";
import Admin_Editorials from "../pods/editorials/podRouter";
import Admin_Media from "../pods/media/media";
import Admin_Contacts from "../pods/contacts/files/contactsControl";
import Admin_Features from "../pods/features/podRouter";

function AdminHome(props) {
    const GS_navSettings = useSelector((state) => state.GS_navSettings);
    const user = useSelector((state) => state.user);
    const [overlay, setOverlay] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuItems = {
        Overview: {
            id: 0,
            title: "Overview",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "overview")
        },
        Users: {
            id: 1,
            title: "Users",
            subMenu: true,
            subMenuItems: [
                {
                    id: 0,
                    title: "General Settings",
                    method: openSubMenuItem.bind(this, "users", "generalsettings")
                }
            ],
            method: openSubMenuAndComponent.bind(this, "users")
        },
        Pages: {
            id: 2,
            title: "Pages",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "pages")
        },
        Editorials: {
            id: 3,
            title: "Editorials",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "editorials")
        },
        Media: {
            id: 4,
            title: "Media",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "media")
        },
        Contacts: {
            id: 5,
            title: "Contacts",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "contacts")
        },
        Features: {
            id: 6,
            title: "Features",
            subMenu: false,
            subMenuItems: [],
            method: changeSection.bind(this, "features")
        },
        LogOut: {
            id: 7,
            title: "Log Out",
            subMenu: false,
            subMenuItems: [],
            method: handleLogOut
        }
    };

    const [mainMenuItems, setMenuItems] = useState([]);

    useEffect(() => {
        onOpen();
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        if (!user.auth) {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else if (user.type === "registered") {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else {
            Axios.post("/adminPods/admin/getPagesAndFeatures")
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error === "null") {
                        let pagesItem = { ...menuItems["Pages"] };
                        let featuresItem = { ...menuItems["Features"] };

                        if (data.pages.error != "no pages") {
                            const subMenu = [];
                            data.pages.items.map((page) => {
                                const subItem = {
                                    id: page.uuid,
                                    title: page.title,
                                    method: openPageSubMenuItem.bind(this, page.uuid)
                                };
                                subMenu.push(subItem);
                            });

                            if (data.pages.more == "true") {
                                const subItem = {
                                    id: data.moreUUID,
                                    title: "More...",
                                    method: null
                                };
                                subMenu.push(subItem);
                            }

                            pagesItem.subMenuItems = subMenu;
                            pagesItem.subMenu = true;
                            pagesItem.method = openSubMenuAndComponent.bind(this, "pages");
                        }
                        if (data.features.error != "no features") {
                            const subMenu = [];
                            data.features.items.map((feature) => {
                                const subItem = {
                                    id: feature.uuid,
                                    title: feature.title,
                                    method: openFeaturesSubMenuItem.bind(this, feature.adminCode)
                                };
                                subMenu.push(subItem);
                            });

                            featuresItem.subMenuItems = subMenu;
                            featuresItem.subMenu = true;
                            featuresItem.method = openSubMenuAndComponent.bind(this, "features");
                        }
                        BuildAdminMenu(pagesItem, featuresItem);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function BuildAdminMenu(pagesItem, featuresItem) {
        let items = [];

        if (user.type == "seniorAdmin" || user.type == "admin") {
            items = ["Overview", "Users", "Pages", "Editorials", "Media", "Contacts", "Features", "LogOut"];
        }
        if (user.type == "modifier") {
            items = ["Overview", "Pages", "Editorials", "Media", "LogOut"];
        }
        const tempMenu = [];

        items.forEach((item) => {
            if (item === "Pages") {
                tempMenu.push(pagesItem);
            } else if (item === "Features") {
                tempMenu.push(featuresItem);
            } else {
                tempMenu.push(menuItems[item]);
            }
        });

        setMenuItems(tempMenu);
    }

    //MENU CHANGE METHODS
    function openSubMenuAndComponent(title) {
        navigate(`./${title}`);
    }

    function changeSection(title) {
        navigate(`./${title}`);
    }

    function openSubMenuItem(sectionTitle, subTitle) {
        const link = `${sectionTitle}/${subTitle.replace(" ", "")}`;
        navigate(`./${link}`);
    }

    function openPageSubMenuItem(subId) {
        navigate(`./pages/settings/${subId}`);
    }

    function openFeaturesSubMenuItem(featureCode) {
        navigate(`./features/pod/${featureCode}`);
    }

    //MAIN MENU ACTIONS
    function handleLogOut() {
        Axios.post("/userLogin/admin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "Admin logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));
                    navigate(`/admin-${data.adminCode}/security/login`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleOnClick() {
        setOverlay(true);
    }

    function handleTurnOffOverlay() {
        setOverlay(false);
    }

    return (
        <div style={AdminHomeStyles.body}>
            <Row>
                <Col md={GS_navSettings.size} style={AdminHomeStyles.title}>
                    {GS_navSettings.size == 3 ? "Administration" : "Admin"}
                </Col>
                <Col md={12 - GS_navSettings.size} style={AdminHomeStyles.sectionTitle}>
                    {GS_navSettings.title}
                </Col>
            </Row>
            <Row>
                <Col md={GS_navSettings.size}>
                    <Admin_NavBar size={GS_navSettings.size} items={mainMenuItems} />
                </Col>
                <Col md={12 - GS_navSettings.size} style={AdminHomeStyles.bodyColumn}>
                    <div style={AdminHomeStyles.mainBodyContainer}>
                        <div style={AdminHomeStyles.mainBody}>
                            <Routes>
                                <Route path="/overview" element={<Admin_Overview />} />
                                <Route path="/users/*" exact element={<Admin_Users />} />
                                <Route path="/pages/*" element={<Admin_Pages />} />
                                <Route path="/editorials/*" element={<Admin_Editorials />} />
                                <Route path="/media" element={<Admin_Media />} />
                                <Route path="/contacts" element={<Admin_Contacts />} />
                                <Route path="/features/*" element={<Admin_Features />} />
                            </Routes>
                            {/* <Button onClick={handleOnClick}>Test</Button> */}
                        </div>
                        {overlay && (
                            <div style={AdminHomeStyles.overlay} onClick={handleTurnOffOverlay}>
                                <p>Click to go back to this section</p>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default AdminHome;
