export const buttons = {
	textAlign: "center",
	paddingLeft: "0px",
	paddingRight: "0px",
	height: "60px",
};

export const btnsHidden = {
	//COL
	visibility: "hidden",
	paddingLeft: "0px",
	paddingRight: "0px",
	height: "60px",
};

export const enlargeText = {
	height: "25px",
	fontWeight: "bold",
	textAlign: "center",
};

export const enlargeTextHidden = {
	height: "25px",
	fontWeight: "bold",
	textAlign: "center",
	visibility: "hidden",
};

export const img = {
	width: "150px",
	height: "150px",
	cursor: "pointer",
};

export const imgContainer = {
	textAlign: "center",
	height: "160px",
	cursor: "pointer",
};

export const textContainer = {
	height: "30px",
	textAlign: "center",
	cursor: "pointer",
};

export const textContainerHidden = {
	visibility: "hidden",
	height: "30px",
	textAlign: "center",
};

//Directory

export const directoryTop = {
	height: "60px",
	cursor: "pointer",
};

export const dirImgContainer = {
	height: "160px",
	textAlign: "center",
	cursor: "pointer",
};

export const dirTextContainer = {
	height: "30px",
	textAlign: "center",
	cursor: "pointer",
};

export const dirTextContainerUnderlined = {
	height: "30px",
	textAlign: "center",
	cursor: "pointer",
	textDecoration: "underline",
};

export const dirPic = {
	width: "150px",
	height: "150px",
	cursor: "pointer",
};

//EMPTY
export const emptyTopContainer = {
	height: "60px",
};

export const emptyEnlargeTextContainter = {
	height: "25px",
};

export const emptyPicContainer = {
	height: "160px",
};

export const emptyBtnContainer = {
	height: "30px",
};

export const imagePreviewWidth = {
	width: "100%",
};

export const insertImageButton = {
	textAlign: "right",
};
