import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const mainMenuItemSmall = {
	textAlign: "center",
	fontSize: "1.5rem",
	backgroundColor: Colors.primary,
	color: Colors.primary,
	padding: "10px",
	userSelect: "none",
};
