import React, { useState } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";

//STYLES
import * as LayoutSlotStyles from "../styles/layoutSlot";

import Pages_LayoutTitle from "./layoutTitle";
import ItemsViewer from "./itemsViewer";

function Pages_LayoutSlot(props) {
    const dispatch = useDispatch();
    const GS_pageSettings = useSelector((state) => state.GS_pageSettings);

    const [itemsViewerModal, setItemsViewerModal] = useState({
        open: false,
        rowID: 0,
        colID: 0,
        colWidth: 0
    });

    function handleCloseItemViewerModal() {
        setItemsViewerModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleIncreaseWidth(rowID, columnID) {
        dispatch(GS_pageSettingsActions.IncreaseWidth(rowID, columnID));
    }

    function handleDecreaseWidth(rowID, columnID) {
        dispatch(GS_pageSettingsActions.DecreaseWidth(rowID, columnID));
    }

    function handleNewColumn(rowID, columnID) {
        dispatch(GS_pageSettingsActions.AddNewColumn(rowID, columnID));
    }

    function handleDeleteColumn(rowID, columnID) {
        dispatch(GS_pageSettingsActions.DeleteColumn(rowID, columnID));
    }

    function handleDeleteRow(rowID) {
        dispatch(GS_pageSettingsActions.DeleteRow(rowID));
    }

    function rightButtonShow(id) {
        if (id == props.columns.length - 1) {
            return false;
        } else if (props.columns.length > 0 && props.columns[id + 1].width == 1) {
            return false;
        } else {
            return true;
        }
    }

    function leftButtonShow(id) {
        if (id == 0) {
            return false;
        } else if (props.columns.length > 0 && props.columns[id - 1].width == 1) {
            return false;
        } else {
            return true;
        }
    }

    function handleOpenItemModal(rowID, colID, colWidth) {
        setItemsViewerModal((prevState) => {
            return { ...prevState, rowID: rowID, colID: colID, colWidth: colWidth, open: true };
        });
    }

    function handleRemoveItem(rowID, colID) {
        dispatch(GS_pageSettingsActions.RemoveItemFromLayout(rowID, colID));
    }

    function columnWidth(section, width) {
        if (section == "end" && width == 1) {
            return 5;
        } else if (section == "middle" && width == 1) {
            return 2;
        } else if (section == "end" && width == 2) {
            return 4;
        } else if (section == "middle" && width == 2) {
            return 4;
        } else if (section == "end" && width > 2) {
            return 3;
        } else if (section == "middle" && width > 2) {
            return 6;
        }
    }

    //5,2,5 - width 1
    //4,4,4 - width 2
    //3,6,3 - width 3+
    return (
        <div>
            <Row>
                {props.columns.map((col, index) => {
                    return (
                        <Col key={index} sm={col.width} style={LayoutSlotStyles.rowContainer}>
                            <Row>
                                <Col md={columnWidth("end", col.width)}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Increase column to the Left</Tooltip>}>
                                        <Button
                                            style={leftButtonShow(col.id) ? null : LayoutSlotStyles.buttonHidden}
                                            onClick={handleDecreaseWidth.bind(this, props.rowID, col.id)}
                                        >
                                            <i className="fa-solid fa-angles-left"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <br />
                                    {col.layoutTitle == "" ? (
                                        <div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>{col.width == 12 ? "Delete Row" : "Delete Column"}</Tooltip>}
                                            >
                                                <Button
                                                    variant="success"
                                                    onClick={
                                                        col.width == 12
                                                            ? handleDeleteRow.bind(this, props.rowID)
                                                            : handleDeleteColumn.bind(this, props.rowID, col.id)
                                                    }
                                                >
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    ) : (
                                        <div>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Remove Item</Tooltip>}>
                                                <Button variant="danger" onClick={handleRemoveItem.bind(this, props.rowID, col.id)}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    )}
                                </Col>
                                <Col md={columnWidth("middle", col.width)} style={LayoutSlotStyles.centerColumn}>
                                    {col.width == 1 ? null : (
                                        <div>
                                            {col.layoutTitle == "" ? (
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Add Item</Tooltip>}>
                                                    <Button onClick={handleOpenItemModal.bind(this, props.rowID, col.id, col.width)}>
                                                        <i className="fa-solid fa-file-circle-plus"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            ) : (
                                                <div style={LayoutSlotStyles.editorialTitleOverflow}>
                                                    {<Pages_LayoutTitle podCode={col.podCode} title={col.layoutTitle} />}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <br />
                                    {col.width}
                                </Col>
                                <Col md={columnWidth("end", col.width)} style={LayoutSlotStyles.rightButtons}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Increase column to the Right</Tooltip>}>
                                        <Button
                                            style={rightButtonShow(col.id) ? null : LayoutSlotStyles.buttonHidden}
                                            onClick={handleIncreaseWidth.bind(this, props.rowID, col.id)}
                                        >
                                            <i className="fa-solid fa-angles-right"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    <br />
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Add Column</Tooltip>}>
                                        <Button
                                            style={col.width > 1 ? null : LayoutSlotStyles.buttonHidden}
                                            onClick={handleNewColumn.bind(this, props.rowID, col.id)}
                                        >
                                            <i className="fa-solid fa-circle-plus"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                    );
                })}
            </Row>
            <Modal show={itemsViewerModal.open} onHide={handleCloseItemViewerModal}>
                <ItemsViewer
                    closeBtn={handleCloseItemViewerModal}
                    rowID={itemsViewerModal.rowID}
                    colID={itemsViewerModal.colID}
                    colWidth={itemsViewerModal.colWidth}
                />
            </Modal>
        </div>
    );
}

{
    /* <Col md={3}>
				
			</Col> */
}

export default Pages_LayoutSlot;
