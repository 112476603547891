import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import Axios from "axios";
import Switch from "react-switch";

//ACTIONS
import * as GS_navSettingsActions from "../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as OverviewStyles from "./styles/overview";
import * as UploadStyles from "../media/styles/upload"; //CROSS-OVER POD LINK

function Admin_Overview() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [connection, setConnection] = useState("");
    const [siteOffline, setSiteOffline] = useState({
        offline: false,
        message: ""
    });
    const [domainName, setDomainName] = useState("");

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalOfflineMessage, setModalOfflineMessage] = useState({
        header: "",
        open: false,
        offlineMessage: "",
        counter: 100,
        maxCounter: 100
    });

    function handleModalOfflinefMessageClose() {
        setModalOfflineMessage((prevState) => {
            return { ...prevState, open: false, offlineMessage: "" };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Overview"));
        dispatch(GS_navSettingsActions.UpdateSelected("Overview"));

        Axios.post("/adminPods/overview/getData")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setConnection(data.connectionMessage);
                    setSiteOffline((prevState) => {
                        return { ...prevState, offline: data.siteOffline.value == "true", message: data.siteOffline.subValue };
                    });
                    setDomainName(data.domain);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handlSiteOfflineChange(checked) {
        setSiteOffline((prevState) => {
            return { ...prevState, offline: checked };
        });

        const data = { switchValue: checked.toString() };

        Axios.post("/adminPods/overview/changeOfflineState", data)
            .then((res) => {
                const data = res.data;
                if (data.error === "Yes") {
                    setModal({
                        header: "Contacts",
                        error: true,
                        message: data.message,
                        open: true
                    });
                    setSiteOffline((prevState) => {
                        return { ...prevState, offline: data.switchValue == "true" };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnlineMessage() {
        setModalOfflineMessage((prevState) => {
            return { ...prevState, header: "Offline Message", open: true, offlineMessage: siteOffline.message };
        });
    }

    function handleOfficeMessageModalTextChange(event) {
        const { value } = event.target;

        const charLength = modalOfflineMessage.maxCounter - value.length;

        setModalOfflineMessage((prevState) => {
            return { ...prevState, offlineMessage: value, counter: charLength };
        });
    }

    function handleModalOfflineMessageSubmit() {
        const data = { message: modalOfflineMessage.offlineMessage };

        Axios.post("/adminPods/overview/setOfflineMessage", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setSiteOffline((prevState) => {
                        return { ...prevState, message: modalOfflineMessage.offlineMessage };
                    });
                    setModalOfflineMessage((prevState) => {
                        return { ...prevState, open: false, offlineMessage: "null" };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={OverviewStyles.body}>
            {user.type === "seniorAdmin" || user.type === "admin" ? (
                <div>
                    <Row>
                        <Col style={OverviewStyles.textCenter}>
                            <h1>Welcome to your HVS-WM System!</h1>
                            <h2>Pick from the options on your right to edit your website</h2>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h3>
                                <strong>Database:</strong>
                            </h3>
                            <h3>{connection}</h3>
                        </Col>
                        <Col>
                            <h3>
                                <strong>Domain Name:</strong>
                            </h3>
                            <h3>{domainName}</h3>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div style={OverviewStyles.mainContactSwitchText}>
                                Site Offline:
                                <Switch
                                    className="customCSS-Content-New-Item-Switch"
                                    onChange={handlSiteOfflineChange}
                                    checked={siteOffline.offline}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                            </div>
                            {siteOffline.offline ? (
                                <div style={OverviewStyles.offlineMessageButton}>
                                    <Button onClick={handleOnlineMessage}>Offline Message</Button>
                                </div>
                            ) : null}
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
            ) : null}
            {user.type === "modifier" ? (
                <div>
                    <Row>
                        <Col style={OverviewStyles.textCenter}>
                            <h1>Welcome to your HVS-WM System!</h1>
                            <h2>Pick from the options on your right to edit your website</h2>
                        </Col>
                    </Row>
                </div>
            ) : null}

            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalOfflineMessage.open} onHide={handleModalOfflinefMessageClose}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modalOfflineMessage.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="This site is down for maintenance"
                        maxLength={modalOfflineMessage.maxCounter}
                        value={modalOfflineMessage.offlineMessage}
                        onChange={handleOfficeMessageModalTextChange}
                    />
                    <div style={OverviewStyles.modalCounter}>{modalOfflineMessage.counter}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalOfflineMessageSubmit}>
                        Set
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admin_Overview;
