import React, { useState, useEffect } from "react";
import { Modal, Card, Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

//STYLES
import * as UsersViewerStyles from "../styles/usersViewer";

function UsersViewer(props) {
  const user = useSelector((state) => state.user);

  const [settings, setSettings] = useState({
    users: [],
  });

  useEffect(() => {
    const data = { type: user.type };

    Axios.post("/adminPods/contacts/getAllUsers", data)
      .then((res) => {
        const data = res.data;
        if (data.error == "null") {
          setSettings((prevState) => {
            return { ...prevState, users: data.users };
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleUserCardClick(id) {
    if (props.mainContactMode) {
      const data = { userID: id };
      Axios.post("/adminPods/contacts/addMainContact", data)
        .then((res) => {
          const data = res.data;
          if (data.error == "null") {
            props.updateMainContact(
              data.user.id,
              data.user.fullName,
              data.user.email
            );
          } else {
            props.openModal("Main Contact", data.message, true, true);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const data = { userID: id };
      Axios.post("/adminPods/contacts/addOtherContact", data)
        .then((res) => {
          const data = res.data;
          if (data.error == "null") {
            props.closeBtn();
            props.updateContacts();
          } else {
            props.openModal("Other Contacts", data.message, true, true);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Pick an User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Card style={UsersViewerStyles.headingCardStyle}>
            <Row>
              <Col sm={5}>User</Col>
              <Col sm={7}>Email</Col>
            </Row>
          </Card>
          <br />
          {settings.users.map((user, index) => {
            return (
              <div key={index}>
                <Card
                  style={UsersViewerStyles.cardStyle}
                  onClick={handleUserCardClick.bind(this, user.id)}
                >
                  <Row>
                    <Col sm={5}>{user.fullName}</Col>
                    <Col sm={7}>{user.email}</Col>
                  </Row>
                </Card>
                <br />
              </div>
            );
          })}
          {props.mainContactMode && props.mainContactID != "" && (
            <Card
              style={UsersViewerStyles.removeBtnCardStyle}
              onClick={props.removeMainContact}
            >
              <Row>
                <Col>Remove User and disable Main Contact</Col>
              </Row>
            </Card>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.closeBtn}>
          Close
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default UsersViewer;
