import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

function Admin_Features() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("Features"));
    dispatch(GS_navSettingsActions.UpdateSelected("Features"));
  }, []);

  return (
    <div>
      <h1>Home to your custom features!</h1>
    </div>
  );
}

export default Admin_Features;
