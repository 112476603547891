import * as ReducerActions from "../../actions/globalSettings/GS_navSettings";

const initialState = {
    size: "3",
    title: "",
    selectedItem: "",
    selectedSubItem: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GS_UPDATE_NAV_SIZING:
            return { ...state, size: action.value };
        case ReducerActions.GS_UPDATE_NAV_TITLE:
            return { ...state, title: action.value };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDITEM:
            return { ...state, selectedItem: action.value, selectedSubItem: "" };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDSUBITEM:
            return { ...state, selectedSubItem: action.value };
        default:
            return state;
    }
};

export default reducer;
