import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

function Admin_Users_GeneralSettings() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(GS_navSettingsActions.UpdateTitle("Users - General Settings"));
		dispatch(GS_navSettingsActions.UpdateSelected("Users"));
		dispatch(GS_navSettingsActions.UpdateSubSelected("General Settings"));
	}, []);

	return (
		<div>
			<h1>Users General Settings!</h1>
		</div>
	);
}

export default Admin_Users_GeneralSettings;
