import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import EditorialControl from "./files/editorials";
import NewEditorialItem from "./files/newEditoralItem";

function PodRouter() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div>
      {isLoaded && (
        <div>
          <Routes>
            <Route path="/" element={<EditorialControl />} />
            <Route
              path="/create"
              element={<NewEditorialItem modify={false} />}
            />
            <Route
              path="/modify/:id"
              element={<NewEditorialItem modify={true} />}
            />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default PodRouter;
