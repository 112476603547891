import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as UserDetailsStyles from "../styles/userDetails";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

function UserDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [userData, setUserData] = useState({
        inputs: {
            name: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            postcode: "",
            tel: "",
            type: "registered",
            password: "emailPass"
        },
        inputsValid: {
            name: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: true
        },
        formValid: {
            nameAndEmail: false,
            address: true,
            wholeForm: false
        },
        touched: {
            name: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false
        }
    });
    const [formValidate, setFormValidate] = useState(false);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Users - Add New User"));
        dispatch(GS_navSettingsActions.UpdateSelected("Users"));
        onOpen();
    }, []);

    function onOpen() {
        if (props.modify) {
            const data = { id: params.id };
            Axios.post("/adminPods/users/getUser", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);

                    const newInputs = {
                        ...userData.inputs,
                        name: data.user.fullName,
                        email: data.user.email,
                        addressLine1: data.user.addressLine1,
                        addressLine2: data.user.addressLine2,
                        town: data.user.town,
                        postcode: data.user.postcode,
                        tel: data.user.tel,
                        type: data.user.type
                    };

                    for (const [key, value] of Object.entries(newInputs)) {
                        ValidateData(key, value);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false,
        closeFunction: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleCloseModalAndBack() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
        navigate("..");
    }

    const [passwordModal, setPasswordModal] = useState({
        header: "",
        open: false,
        message: "",
        password: "",
        error: false,
        closeFunction: ""
    });

    function handleClosePasswordModal() {
        setPasswordModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleClosePasswordModalAndBack() {
        setPasswordModal((prevState) => {
            return { ...prevState, open: false };
        });
        navigate("..");
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function ValidateData(name, value) {
        const inputs = userData.inputs;
        const valids = userData.inputsValid;
        const form = userData.formValid;

        inputs[name] = value;

        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }
        valids[name] = isValid;

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            valids[name] = false;
        }

        if (valids.name && valids.email) {
            form.nameAndEmail = true;
        } else {
            form.nameAndEmail = false;
        }

        let isFullAddress = false;

        if (!valids.addressLine1 && !valids.addressLine2 && !valids.town && !valids.postcode) {
            isFullAddress = true;
        } else if (valids.addressLine1 && valids.addressLine2 && valids.town && valids.postcode) {
            isFullAddress = true;
        }

        form.address = isFullAddress;

        let telIsValid = true;

        if (name === "tel" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(inputs.tel)) {
            telIsValid = false;
        }

        valids.tel = telIsValid;

        if (form.nameAndEmail && form.address && valids.tel) {
            form.wholeForm = true;
        } else {
            form.wholeForm = false;
        }

        setUserData((prevState) => {
            return { ...prevState, inputs: inputs, inputsValid: valids, formValid: form };
        });
    }

    function handleInputChange(event) {
        const { name, value } = event.target;

        const inputs = userData.inputs;
        const valids = userData.inputsValid;
        const form = userData.formValid;

        inputs[name] = value;

        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }
        valids[name] = isValid;

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            valids[name] = false;
        }

        if (valids.name && valids.email) {
            form.nameAndEmail = true;
        } else {
            form.nameAndEmail = false;
        }

        let isFullAddress = false;

        if (!valids.addressLine1 && !valids.addressLine2 && !valids.town && !valids.postcode) {
            isFullAddress = true;
        } else if (valids.addressLine1 && valids.addressLine2 && valids.town && valids.postcode) {
            isFullAddress = true;
        }

        form.address = isFullAddress;

        let telIsValid = true;

        if (inputs.tel !== "" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(inputs.tel)) {
            telIsValid = false;
        }

        valids.tel = telIsValid;

        if (form.nameAndEmail && form.address && valids.tel) {
            form.wholeForm = true;
        } else {
            form.wholeForm = false;
        }

        setUserData((prevState) => {
            return { ...prevState, inputs: inputs, inputsValid: valids, formValid: form };
        });
    }

    function updateTouched(event) {
        const { name } = event.target;

        const updatedTouched = {
            ...userData.touched,
            [name]: true
        };

        setUserData((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function onInputTel(event) {
        let { value } = event.target;

        value = 1;
    }

    function CheckType(type) {
        if (userData.inputs.type === type) {
            return true;
        } else {
            return false;
        }
    }

    function handleCheckChanged(event) {
        const { name } = event.target;

        const newInputs = { ...userData.inputs, type: name };

        setUserData((prevState) => {
            return { ...prevState, inputs: newInputs };
        });
    }

    function CheckPassword(password) {
        if (userData.inputs.password === password) {
            return true;
        } else {
            return false;
        }
    }

    function handleCheckPasswordChanged(event) {
        const { name } = event.target;

        const newInputs = { ...userData.inputs, password: name };

        setUserData((prevState) => {
            return { ...prevState, inputs: newInputs };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(userData.touched).map((k) => ({ [k]: true })));
        setUserData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleSaveUser() {
        if (!userData.formValid.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                name: userData.inputs.name,
                email: userData.inputs.email,
                addressLine1: userData.inputs.addressLine1,
                addressLine2: userData.inputs.addressLine2,
                town: userData.inputs.town,
                postcode: userData.inputs.postcode,
                tel: userData.inputs.tel,
                type: userData.inputs.type,
                passwordOption: userData.inputs.password
            };

            Axios.post("/adminPods/users/addUser", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error === "Yes") {
                        setModal({ header: "Add New User", message: data.message, error: true, closeFunction: handleCloseModal, open: true });
                    } else {
                        if (data.passwordOption === "viewPass") {
                            setPasswordModal({
                                header: "Add New User",
                                message: data.message,
                                error: false,
                                password: data.password,
                                closeFunction: handleClosePasswordModalAndBack,
                                open: true
                            });
                        } else {
                            setModal({
                                header: "Add New User",
                                message: data.message,
                                error: false,
                                closeFunction: handleCloseModalAndBack,
                                open: true
                            });
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleSelectResetPassOption(event) {
        const { name } = event.target;

        if (name === "emailPass") {
            const message = "Are you sure you want to reset this user's password and email them?";

            setModalYN({
                heading: "Reset Password",
                message: message,
                showAccept: true,
                acceptName: "Yes",
                acceptFunction: accept_ResetPassword.bind(this, "email"),
                showCancel: true,
                cancelName: "No",
                open: true
            });
        }

        if (name === "viewPass") {
            const message = "Are you sure you want to reset this user's password and view the password?";

            setModalYN({
                heading: "Reset Password",
                message: message,
                showAccept: true,
                acceptName: "Yes",
                acceptFunction: accept_ResetPassword.bind(this, "view"),
                showCancel: true,
                cancelName: "No",
                open: true
            });
        }
    }

    function accept_ResetPassword(option) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = { id: params.id, option: option };

        Axios.post("/adminPods/security/resetPassword", data)
            .then((res) => {
                const data = res.data;
                if (data.error === "null") {
                    if (data.option === "email") {
                        setModal({
                            header: "Reset Password",
                            message: "The user has been sent an email containing a temporary password",
                            closeFunction: handleCloseModal,
                            error: false,
                            open: true
                        });
                    }
                    if (data.option === "view") {
                        setPasswordModal({
                            header: "Reset Password",
                            message: "The password for this user has been reset to: ",
                            error: false,
                            password: data.password,
                            closeFunction: handleClosePasswordModal,
                            open: true
                        });
                    }
                }
            })
            .catch((err) => console.log(err));
    }

    function handleUpdateUser() {
        if (!userData.formValid.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                id: params.id,
                name: userData.inputs.name,
                addressLine1: userData.inputs.addressLine1,
                addressLine2: userData.inputs.addressLine2,
                town: userData.inputs.town,
                postcode: userData.inputs.postcode,
                tel: userData.inputs.tel,
                type: userData.inputs.type
            };

            Axios.post("/adminPods/users/updateUser", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error === "Yes") {
                        setModal({ header: "Update User", message: data.message, error: true, closeFunction: handleCloseModal, open: true });
                    } else {
                        setModal({ header: "Update User", message: data.message, error: false, closeFunction: handleCloseModalAndBack, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div style={UserDetailsStyles.body}>
            <br />
            <Row>
                <Col>
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Name:
                        </Col>
                        <Col sm={9}>
                            <Form.Control
                                isInvalid={userData.touched.name && !userData.inputsValid.name ? true : false}
                                type="text"
                                name="name"
                                placeholder="required"
                                value={userData.inputs.name}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Email:
                        </Col>
                        <Col sm={9}>
                            <Form.Control
                                isInvalid={userData.touched.email && !userData.inputsValid.email ? true : false}
                                type="text"
                                name="email"
                                placeholder="required"
                                value={userData.inputs.email}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                                disabled={props.modify ? true : false}
                            />
                            {props.modify && (
                                <div style={UserDetailsStyles.emailOnModify}>
                                    <i className="fa-solid fa-triangle-exclamation"></i> Email cannot be changed
                                </div>
                            )}
                            <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Address Line 1:
                        </Col>
                        <Col sm={9}>
                            <Form.Control
                                isInvalid={
                                    userData.touched.addressLine1 && !userData.formValid.address && !userData.inputsValid.addressLine1 ? true : false
                                }
                                type="text"
                                name="addressLine1"
                                value={userData.inputs.addressLine1}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter the full address</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Address Line 2:
                        </Col>
                        <Col sm={9}>
                            <Form.Control
                                isInvalid={
                                    userData.touched.addressLine2 && !userData.formValid.address && !userData.inputsValid.addressLine2 ? true : false
                                }
                                type="text"
                                name="addressLine2"
                                value={userData.inputs.addressLine2}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter the full address</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Town / City:
                        </Col>
                        <Col sm={4}>
                            <Form.Control
                                isInvalid={userData.touched.town && !userData.formValid.address && !userData.inputsValid.town ? true : false}
                                type="text"
                                name="town"
                                value={userData.inputs.town}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter the full address</Form.Control.Feedback>
                        </Col>
                        <Col sm={2} style={UserDetailsStyles.formLabels}>
                            Postcode:
                        </Col>
                        <Col sm={3}>
                            <Form.Control
                                isInvalid={userData.touched.postcode && !userData.formValid.address && !userData.inputsValid.postcode ? true : false}
                                type="text"
                                name="postcode"
                                value={userData.inputs.postcode}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter the full address</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={3} style={UserDetailsStyles.formLabels}>
                            Telephone:
                        </Col>
                        <Col sm={9}>
                            <Form.Control
                                isInvalid={userData.touched.tel && !userData.inputsValid.tel ? true : false}
                                type="text"
                                name="tel"
                                value={userData.inputs.tel}
                                onChange={handleInputChange}
                                onBlur={updateTouched}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid telephone number</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col sm={2} style={UserDetailsStyles.formLabels}>
                            Type:
                        </Col>
                        <Col sm={6} style={UserDetailsStyles.formRadioBtns}>
                            <Form.Check type="radio" name="admin" label="Admin" checked={CheckType("admin")} onChange={handleCheckChanged} />
                            <Form.Check type="radio" name="modifier" label="Modifier" checked={CheckType("modifier")} onChange={handleCheckChanged} />
                            <Form.Check
                                type="radio"
                                name="registered"
                                label="Registered"
                                checked={CheckType("registered")}
                                onChange={handleCheckChanged}
                            />
                        </Col>
                        <Col sm={4}></Col>
                    </Row>
                    <Row>
                        <Col style={UserDetailsStyles.passwordSection}>
                            {props.modify ? (
                                <div>
                                    <strong>
                                        <h3>Reset Password:</h3>
                                    </strong>
                                    This will be a temporary password, once this user signs in next time, it will ask them to change their password.
                                    <Dropdown>
                                        <Dropdown.Toggle>Select Option</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item name="emailPass" onClick={handleSelectResetPassOption}>
                                                Email Password to User (Recommended)
                                            </Dropdown.Item>
                                            <Dropdown.Item name="viewPass" onClick={handleSelectResetPassOption}>
                                                View Password
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : (
                                <div>
                                    <strong>
                                        <h3>Password:</h3>
                                    </strong>
                                    The password will be generated automatically. This will be a temporary password, once this user signs in the first
                                    time, it will ask them to create a new password. <br /> Please pick from the following two options: <br /> <br />
                                    <Form.Check
                                        type="radio"
                                        name="emailPass"
                                        label="Email temporary password to user on Save (Recommended)"
                                        checked={CheckPassword("emailPass")}
                                        onChange={handleCheckPasswordChanged}
                                    />
                                    <Form.Check
                                        type="radio"
                                        name="viewPass"
                                        label="Don't email, view temporary password on Save"
                                        checked={CheckPassword("viewPass")}
                                        onChange={handleCheckPasswordChanged}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col style={UserDetailsStyles.saveButton}>
                    {props.modify ? (
                        <div>
                            <div>
                                <strong>
                                    Updating user by the button below will not reset their password, please use the &#39;Reset Password&#39; option
                                    bove
                                </strong>
                            </div>
                            <Button onClick={handleUpdateUser}>Update</Button>
                        </div>
                    ) : (
                        <div>
                            <Button onClick={handleSaveUser}>Save</Button>
                        </div>
                    )}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={modal.closeFunction}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={passwordModal.open} onHide={handleClosePasswordModal}>
                <Modal.Header closeButton style={passwordModal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{passwordModal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {passwordModal.message}
                    <br />
                    <br />
                    Temporary Password: {passwordModal.password}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={passwordModal.closeFunction}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserDetails;
