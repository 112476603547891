import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
  paddingRight: "30px",
};

export const cardStyle = {
  backgroundColor: Colors.primary,
  color: "white",
  cursor: "pointer",
};

export const editorialContainer = {
  paddingTop: "20px",
};

export const cardHeadings = {
  color: "yellow",
};
