import React from "react";

function PodViewer(props) {
  return (
    <div>
      <h1>PodViewer</h1>
    </div>
  );
}

export default PodViewer;
