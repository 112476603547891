import * as ReducerActions from "../../actions/globalSettings/GS_contentSettings";

const initialState = {
	images: [],
	deleted: [],
	id: "",
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ReducerActions.GSCONTENTSETTINGS_UPDATEIMAGES:
			return { ...state, images: action.value };
		case ReducerActions.GSCONTENTSETTINGS_UPDATEID:
			return { ...state, id: action.value };
		case ReducerActions.GSCONTENTSETTINGS_INSERTDELETED:
			return { ...state, deleted: [...state.deleted, action.value] };
		default:
			return state;
	}
};

export default reducer;
