export const body = {
	paddingRight: "30px",
};

export const formLabels = {
	fontSize: "1.5rem",
};

export const placeholderText = {
	border: "2px solid red",
};

export const inputText = {
	border: "0px solid black",
};

export const formRadioBtns = {
	fontSize: "1.5rem",
	justifyContent: "space-between",
	display: "flex",
};

export const saveButton = {
	textAlign: "right",
};

export const passwordSection = {
	paddingTop: "30px",
	fontSize: "1.5rem",
};

export const emailOnModify = {
	color: "red",
};
