import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    position: "relative",
    backgroundColor: Colors.accent,
    margin: "1px",
    minHeight: "100vh"
};

export const adminLoginOuterContainer = {
    marginTop: "20px"
    // left: "25%",
    // top: "25%",
};

export const adminLoginInnerContainer = {
    backgroundColor: Colors.secondary,
    // position: "fixed",
    // top: "37.5%",
    // left: "37.5%",
    // marginTop: "0px",
    color: "black"
};

export const forgotPassword = {
    color: "white",
    textAlign: "right",
    fontSize: "2rem",
    position: "absolute",
    bottom: "0"
};

export const helpIcon = {
    cursor: "pointer"
};

export const innerContainer = {
    backgroundColor: Colors.secondary,
    color: "black"
};

export const loginBtns = {
    color: Colors.accent,
    fontWeight: "bold"
};

export const loginBtnsHidden = {
    color: Colors.accent,
    fontWeight: "bold",
    visibility: "hidden"
};

export const hidden = {
    visibility: "hidden"
};

export const visibile = {
    visibility: "visible"
};

export const changePasswordTitles = {
    textAlign: "right"
};

export const passwordHelp = {
    color: "red",
    fontWeight: "bold"
};
