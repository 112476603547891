import * as Colors from "../../../environment/globalStyles/administratorColors";

export const body = {
    minHeight: "100vh",
    backgroundColor: Colors.secondary,
    margin: "1px",
    overflow: "hidden"
};

export const title = {
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#163172",
    color: "white"
};

export const mainMenuItem = {
    textAlign: "center",
    fontSize: "1.5rem",
    backgroundColor: Colors.primary,
    color: "white",
    padding: "10px"
};

export const sectionTitle = {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold"
};

export const mainBodyEnabled = {
    display: "absolute",
    width: "100%",
    height: "100vh",
    opacity: 1
};

// export const mainBodyDisabled = {
//     display: "absolute",
//     width: "100%",
//     height: "100vh",
//     opacity: 0.3,
//     pointerEvents: "none"
// };

// export const overlayEnabled = {
//     display: "relative",
//     width: "100%",
//     height: "100vh"
// };

// export const overlayDisabled = {
//     display: "relative",
//     width: "100%",
//     height: "100vh"
// };

// export const mainColumn = {
//     margin: 0,
//     padding: 0
// };

export const mainBodyContainer = {
    width: "100%",
    height: "100vh"
};

export const mainBody = {
    position: "absolute",
    width: "100%",
    height: "100vh",
    paddingLeft: "20px"
};

export const overlay = {
    position: "absolute",
    backgroundColor: "rgba(128,128,128, .3)",
    width: "100%",
    height: "100vh",
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5rem"
};

export const bodyColumn = {
    paddingLeft: 0
};
