import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
  paddingRight: "30px",
};

export const cardStyle = {
  backgroundColor: Colors.primary,
  color: "white",
  cursor: "pointer",
};

export const mainContactButton = {
  textAlign: "center",
};

export const mainContactLabels = {
  margin: "auto",
};

export const mainContactSwitchText = {
  fontSize: "1.5rem",
};

export const otherContactsAddBtn = {
  textAlign: "right",
};
