export const body = {
    padding: "30px"
};

export const textCenter = {
    textAlign: "center"
};

export const mainContactSwitchText = {
    fontSize: "1.5rem"
};

export const offlineMessageButton = {
    padding: "10px"
};

export const modalCounter = {
    textAlign: "right",
    color: "red"
};
