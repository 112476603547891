export const formLabels = {
    textAlign: "right"
};

export const m_formLabels = {
    textAlign: "left"
};

export const reCAPTCHA = {
    textAlign: "center",
    margin: 0,
    display: "inline-block"
};

export const errorMessage = {
    color: "yellow"
};

export const hidden = {
    visibility: "hidden"
};

export const unhidden = {
    visibility: "visible"
};

export const detailsHeadings = {
    textAlign: "right"
};

export const m_detailsHeadings = {
    textAlign: "left"
};

export const details = {
    textAlign: "left"
};
