export const MEDIA_UPDATE_FILES = "MEDIA_UPDATE_FILES";
export const MEDIA_UPDATE_CURRENTURI = "MEDIA_UPDATE_CURRENTURI";
export const MEDIA_UPDATE_GALLERYWIDTH = "MEDIA_UPDATE_GALLERYWIDTH";
export const MEDIA_UPDATE_CARDANDROWS = "MEDIA_UPDATE_CARDANDROWS";
export const MEDIA_UPDATE_WIDTHROWCARDS = "MEDIA_UPDATE_WIDTHROWCARDS";
export const MEDIA_UPDATE_PODDIRECTORYEXISTS =
  "MEDIA_UPDATE_PODDIRECTORYEXISTS";

export const MEDIA_UPDATE_MOVE_WIDTH = "MEDIA_UPDATE_MOVE_WIDTH";
export const MEDIA_UPDATE_MOVE_DIRECTORIES = "MEDIA_UPDATE_MOVE_DIRECTORIES";
export const MEDIA_UPDATE_MOVE_CARDSANDROWS = "MEDIA_UPDATE_MOVE_CARDSANDROWS";
export const MEDIA_UPDATE_MOVE_WIDTHROWCARDS =
  "MEDIA_UPDATE_MOVE_WIDTHROWCARDS";
export const MEDIA_UPDATE_MOVE_FILE = "MEDIA_UPDATE_MOVE_FILE";
export const MEDIA_UPDATE_MOVE_FILEPATH = "MEDIA_UPDATE_MOVE_FILEPATH";

export const MEDIA_UPDATE_CANVAS_OPEN = "MEDIA_UPDATE_CANVAS_OPEN";

export const UpdateFiles = (files, empty, subDir) => {
  return {
    type: MEDIA_UPDATE_FILES,
    files: files,
    empty: empty,
    subDir: subDir,
  };
};

export const UpdateCurrentUri = (val) => {
  return {
    type: MEDIA_UPDATE_CURRENTURI,
    value: val,
  };
};

export const UpdateGalleryWidth = (val) => {
  return {
    type: MEDIA_UPDATE_GALLERYWIDTH,
    value: val,
  };
};

export const UpdateCardAndRowTotals = (fileTotal) => {
  return (dispatch, getState) => {
    const media = getState().media;

    const cardsPerRow = Math.floor(media.galleryWidth / 200);
    const numberOfRows = Math.ceil(fileTotal / cardsPerRow);
    let cardsArray = [];
    for (let i = 0; i < cardsPerRow; i++) {
      cardsArray.push(i + 1);
    }

    let rowsArray = [];
    for (let i = 0; i < numberOfRows; i++) {
      rowsArray.push(i + 1);
    }

    dispatch({
      type: MEDIA_UPDATE_CARDANDROWS,
      rows: rowsArray,
      cards: cardsArray,
    });
  };
};

export const UpdateWidthRowCardTotals = (galleryWidth) => {
  return (dispatch, getState) => {
    const media = getState().media;

    const cardsPerRow = Math.floor(galleryWidth / 200);
    const numberOfRows = Math.ceil(media.files.length / cardsPerRow);
    let cardsArray = [];
    for (let i = 0; i < cardsPerRow; i++) {
      cardsArray.push(i + 1);
    }

    let rowsArray = [];
    for (let i = 0; i < numberOfRows; i++) {
      rowsArray.push(i + 1);
    }

    dispatch({
      type: MEDIA_UPDATE_WIDTHROWCARDS,
      galleryWidth: galleryWidth,
      rows: rowsArray,
      cards: cardsArray,
    });
  };
};

export const UpdatePodDirectoryExsits = (val) => {
  return {
    type: MEDIA_UPDATE_PODDIRECTORYEXISTS,
    value: val,
  };
};

//MOVE MODAL ACTIONS
export const Update_Move_Directories = (val) => {
  return {
    type: MEDIA_UPDATE_MOVE_DIRECTORIES,
    value: val,
  };
};

export const Update_Move_Width = (val) => {
  return {
    type: MEDIA_UPDATE_MOVE_WIDTH,
    value: val,
  };
};

export const Update_Move_CardAndRowTotals = (dirTotal) => {
  return (dispatch, getState) => {
    const media = getState().media;

    const cardsPerRow = Math.floor(media.move_width / 200);
    const numberOfRows = Math.ceil(dirTotal / cardsPerRow);
    let cardsArray = [];
    for (let i = 0; i < cardsPerRow; i++) {
      cardsArray.push(i + 1);
    }

    let rowsArray = [];
    for (let i = 0; i < numberOfRows; i++) {
      rowsArray.push(i + 1);
    }

    dispatch({
      type: MEDIA_UPDATE_MOVE_CARDSANDROWS,
      rows: rowsArray,
      cards: cardsArray,
    });
  };
};

export const Update_Move_WidthRowCardTotals = (moveWidth) => {
  return (dispatch, getState) => {
    const media = getState().media;

    const cardsPerRow = Math.floor(moveWidth / 200);
    const numberOfRows = Math.ceil(media.move_directories.length / cardsPerRow);
    let cardsArray = [];
    for (let i = 0; i < cardsPerRow; i++) {
      cardsArray.push(i + 1);
    }

    let rowsArray = [];
    for (let i = 0; i < numberOfRows; i++) {
      rowsArray.push(i + 1);
    }

    dispatch({
      type: MEDIA_UPDATE_MOVE_WIDTHROWCARDS,
      moveWidth: moveWidth,
      rows: rowsArray,
      cards: cardsArray,
    });
  };
};

export const Update_Move_File = (val) => {
  return {
    type: MEDIA_UPDATE_MOVE_FILE,
    value: val,
  };
};

export const Update_Move_FilePath = (val) => {
  return {
    type: MEDIA_UPDATE_MOVE_FILEPATH,
    value: val,
  };
};

export const UpdateCanvasOpen = (val) => {
  return {
    type: MEDIA_UPDATE_CANVAS_OPEN,
    value: val,
  };
};
