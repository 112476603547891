import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Axios from "axios";
import * as UserActions from "./store/actions/user";
import { useDispatch } from "react-redux";
import { isBrowser } from "react-device-detect";

//Styles
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.css";
import "./administration/pages/styles/customCSSStyles.css";
import "./environment/globalStyles/customNavCSSStyles.css";
<script src="https://kit.fontawesome.com/c37858cff5.js" crossOrigin="anonymous"></script>;
import "react-datepicker/dist/react-datepicker.css";

//Components
import UserRouteSystem from "./environment/pages/userRouteSystem";
import AdminRouteSystem from "./administration/adminRouteSystem";

function App() {
    const [loaded, setLoaded] = useState(false);
    const [version, setVersion] = useState("web");
    const [adminCode, setAdminCode] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        onOpen();
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        Axios.get("/userLogin/auth", { withCredentials: true })
            .then((res) => {
                const data = res.data;
                // console.log(data);
                if (res.data.message === "User has logged in") {
                    dispatch(UserActions.UpdateAuth(true));
                    dispatch(UserActions.UpdateID(data.user.id));
                    dispatch(UserActions.UpdateName(data.user.fullName));
                    dispatch(UserActions.UpdateEmail(data.user.email));
                    dispatch(UserActions.UpdateType(data.user.type));
                    dispatch(UserActions.UpdateNew(data.user.new == "true"));
                    dispatch(UserActions.UpdateRequestedPassword(data.user.requestedPassword == "true"));
                    setAdminCode(data.adminCode);
                    dispatch(UserActions.UpdateIsLoaded(true));
                } else {
                    setAdminCode(data.adminCode);
                    dispatch(UserActions.UpdateIsLoaded(true));
                }
                setLoaded(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            {loaded ? (
                <Router>
                    <Routes>
                        <Route path="/*" element={<UserRouteSystem />} />
                        <Route path={`/admin-${adminCode}/*`} element={<AdminRouteSystem adminCode={adminCode} />} />
                    </Routes>
                </Router>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default App;
