import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import UserControl from "./files/usersControl";
import GeneralSettings from "./files/generalSettings";
import UserDetails from "./files/userDetails";

function PodRouter() {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div>
			{isLoaded && (
				<div>
					<Routes>
						<Route path="/" element={<UserControl />} />
						<Route path="/generalsettings" element={<GeneralSettings />} />
						<Route path="/create" element={<UserDetails modify={false} />} />
						<Route path="/modify/:id" element={<UserDetails modify={true} />} />
					</Routes>
				</div>
			)}
		</div>
	);
}

export default PodRouter;
