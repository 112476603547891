import React, { useState } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

//STYLES
import * as LayoutSlotStyles from "../styles/layoutSlot";

function Pages_PreLoadedTemplates_LayoutSlot(props) {
  const [entered, setEntered] = useState(false);

  function handleOnMoveOver() {
    setEntered(true);
  }

  function handleOnMoveLeave() {
    setEntered(false);
  }

  return (
    <div onMouseOver={handleOnMoveOver} onMouseLeave={handleOnMoveLeave}>
      <Row style={LayoutSlotStyles.preLoadedLayoutSlot}>
        <strong style={entered ? LayoutSlotStyles.preLoadedSlot_Title : null}>
          {props.title}
        </strong>
        {props.columns.map((col, index) => {
          return (
            <Col
              key={index}
              sm={col.width}
              style={LayoutSlotStyles.rowContainer}
            >
              <Row>
                <Col style={LayoutSlotStyles.centeredText}>{col.width}</Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Pages_PreLoadedTemplates_LayoutSlot;
