import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    height: "100%",
    paddingRight: "20px",
    paddingLeft: "10px"
};

export const templateContainer = {
    border: "2px solid",
    height: "400px",
    padding: "10px",
    overflowY: "auto"
};

export const topText = {
    fontSize: "1.5rem",
    paddingBottom: "10px"
};

export const preLoadedButton = {
    textAlign: "right"
};

export const hepImage = {
    width: "40px",
    height: "50px",
    cursor: "pointer"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardRedStyle = {
    backgroundColor: "#f59042",
    // color: Colors.primary,
    cursor: "pointer"
};

export const backBtnStyle = {
    backgroundColor: "red",
    textAlign: "center",
    color: "white",
    cursor: "pointer"
};
