import React, { useState } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import path from "path";

import * as MediaActions from "../../../store/actions/media";

//STYLES
import * as UploadStyles from "./styles/upload";

function Media_Upload() {
    const [files, setFiles] = useState(null);
    const media = useSelector((state) => state.media);
    const dispatch = useDispatch();

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [failedFilesModal, setFailedFilesModal] = useState({
        files: [],
        open: false
    });

    function handleCloseFailedFilesModal() {
        setFailedFilesModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function onFormSubmit(e) {
        e.preventDefault();
        let localFiles = [];

        for (const [key, file] of Object.entries(files)) {
            localFiles.push({
                id: key,
                name: file.name,
                size: file.size,
                type: file.type
            });
        }

        const checkData = {
            files: localFiles,
            directory: media.currentURI
        };

        const uploadFormData = new FormData();
        uploadFormData.append("directory", media.currentURI);
        const uploadConfig = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
        Axios.post("/adminPods/fileSystem/checkFileExists", checkData)
            .then((res) => {
                const firstData = res.data;
                //console.log(firstData);
                if (firstData.limitError == "Yes") {
                    setModal({
                        error: true,
                        header: "File Limit Error",
                        message: "You can only upload 5 files at a time!",
                        open: true
                    });
                } else {
                    localFiles.forEach((lFile) => {
                        const file = firstData.files.find((file) => file.id === lFile.id);
                        if (file == null) {
                            uploadFormData.append("photos", files[lFile.id]);
                        }
                    });
                    Axios.post("/adminPods/fileSystem/uploadFiles", uploadFormData, uploadConfig)
                        .then((res) => {
                            const secondData = res.data;
                            console.log(secondData);
                            if (secondData.name === "MulterError") {
                                setModal({
                                    error: true,
                                    header: "File Upload: Error",
                                    message: secondData.message,
                                    open: true
                                });
                            } else if (firstData.failed) {
                                setFailedFilesModal({ files: firstData.files, open: true });
                            } else {
                                setModal({
                                    error: false,
                                    header: "File Upload: Success!",
                                    message: secondData.message,
                                    open: true
                                });
                                //NEW CHANGE
                                dispatch(MediaActions.UpdateFiles(secondData.files.entries, secondData.files.empty, secondData.files.subDir));
                                dispatch(MediaActions.UpdateCardAndRowTotals(secondData.files.entries.length));
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function onInputChange(e) {
        setFiles(e.target.files);
    }

    return (
        <div style={UploadStyles.textSize}>
            <Form onSubmit={onFormSubmit}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Control type="file" name="photo" onChange={onInputChange} multiple /> <br />
                                <strong>max. 5 files, image format only, max 1MB per file</strong>
                            </Col>
                            <Col>
                                <Button type="submit">Upload</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </Form>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={failedFilesModal.open} onHide={handleCloseFailedFilesModal}>
                <Modal.Header closeButton style={UploadStyles.filesTableheading}>
                    <Modal.Title>Upload: Failed Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The following files could not be uploaded as to the reasons given
                        <br />
                        <table border="1px" width="100%">
                            <thead>
                                <tr>
                                    <th>File</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedFilesModal.files.map((file, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={UploadStyles.filesTableRow}>{file.file}</td>
                                            <td style={UploadStyles.filesTableRow}>{file.message}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <br />
                        <strong>Spaces in file names will be replaced by a &#39;_&#39; (underscore)</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFailedFilesModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Media_Upload;
