export const headings = {
    fontWeight: "bold",
    border: "solid 1px",
    textAlign: "center"
};

export const cell = {
    border: "solid 1px"
};

export const viewCell = {
    border: "solid 1px",
    textAlign: "center"
};
export const viewBtn = {
    color: "white"
};
