import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const centerContainer = {
	backgroundColor: Colors.primary,
	textAlign: "center",
	color: "white",

	borderRadius: "25px",

	padding: "30px",
	fontSize: "18px",
	marginTop: "10px",
};

export const sideContainer = {
	backgroundColor: Colors.primary,
	color: "white",
	fontSize: "18px",

	margin: "0px 10px",
	padding: "30px",

	borderRadius: "25px",
	marginTop: "10px",
};
