import { combineReducers } from "redux";
import user from "./user";
import media from "./media";

//GLOBAL SETTINGS
import GS_contentSettings from "./globalSettings/GS_contentSettings";
import GS_navSettings from "./globalSettings/GS_navSettings";
import GS_pageSettings from "./globalSettings/GS_pageSettings";
import GS_generalSettings from "./globalSettings/GS_generalSettings";

import * as environmentReducers from "./environmentReducers/index";

const rootReducer = combineReducers({
    user: user,
    media: media,
    GS_contentSettings: GS_contentSettings,
    GS_navSettings: GS_navSettings,
    GS_pageSettings: GS_pageSettings,
    GS_generalSettings: GS_generalSettings,
    ...environmentReducers.pods
});

export default rootReducer;
