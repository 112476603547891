import React from "react";
import { useParams } from "react-router-dom";

import EnvironmentPodsMap from "../../../../environment/pods/environmentPodsMap";

function FeatureArea() {
    const params = useParams();

    return <div>{<EnvironmentPodsMap podCode={params.code} />}</div>;
}

export default FeatureArea;
