import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

function Admin_Pages() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Pages"));
        dispatch(GS_navSettingsActions.UpdateSelected("Pages"));
    }, []);

    function onLoad() {}

    return (
        <div>
            <h3>This section lets you customise the layouts on each page.</h3>
            <h4>Pick a page from the menu</h4>
        </div>
    );
}

export default Admin_Pages;
