import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Pages_PreLoadedTemplates_LayoutSlot from "./preLoadedTemplates_layoutSlot";

//ACTIONS
import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";

function Pages_PreLoadedTemplates(props) {
    const dispatch = useDispatch();
    const templates = [
        {
            id: 0,
            title: "One Column",
            columns: [{ id: 0, width: 12, layoutTitle: "", podCode: "", addInfo: "", status: "create" }]
        },
        {
            id: 1,
            title: "Two Columns (equal)",
            columns: [
                { id: 0, width: 6, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 6, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        },
        {
            id: 2,
            title: "Two Columns (large right column)",
            columns: [
                { id: 0, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 9, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        },
        {
            id: 3,
            title: "Two Columns (large left column)",
            columns: [
                { id: 0, width: 9, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        },
        {
            id: 4,
            title: "Three Columns (equal)",
            columns: [
                { id: 0, width: 4, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 4, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 2, width: 4, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        },
        {
            id: 5,
            title: "Three Columns (large center)",
            columns: [
                { id: 0, width: 2, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 8, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 2, width: 2, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        },
        {
            id: 6,
            title: "Four Columns (gallery view)",
            columns: [
                { id: 0, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 1, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 2, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" },
                { id: 3, width: 3, layoutTitle: "", podCode: "", addInfo: "", status: "create" }
            ]
        }
    ];

    function handleOnTemplateClick(columns) {
        dispatch(GS_pageSettingsActions.InsertNewLayoutRowFromTemplate(columns));
        props.closeBtn();
    }

    return (
        <div>
            <Modal.Header>
                <Modal.Title>Pre-loaded Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>
                    These are examples of what you can have in the layout, but not limited to. Click on a template to quickly insert it into your
                    layout.
                </strong>
                <br /> <br />
                {templates.map((template, index) => {
                    return (
                        <div key={index} onClick={handleOnTemplateClick.bind(this, template.columns)}>
                            <Pages_PreLoadedTemplates_LayoutSlot rowID={template.id} columns={template.columns} title={template.title} />
                            <br />
                        </div>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.closeBtn}>
                    Close
                </Button>
            </Modal.Footer>
        </div>
    );
}

export default Pages_PreLoadedTemplates;
