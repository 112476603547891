import React, { useRef, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//STYLES
import * as LayoutSlotStyles from "../styles/layoutSlot";

function Pages_LayoutTitle(props) {
    const ref = useRef(null);
    const [title, setTitle] = useState(props.title);
    const [overflow, setOverflow] = useState(false);
    const [type, setType] = useState("");

    useEffect(() => {
        if (ref.current.scrollWidth > ref.current.offsetWidth) {
            setOverflow(true);
        } else {
            setOverflow(false);
        }
        if (props.podCode == "ADMIN_EDITORIALS") {
            setType("editorial");
        } else if (props.podCode == "ADMIN_CONTACTS") {
            setType("contact");
        } else {
            setType("pod");
        }
    }, [props]); //need props here to make sure it re-renders when changing the layout

    return (
        <div ref={ref}>
            {overflow ? (
                <div>
                    {type == "pod" && (
                        <div>
                            <div
                                style={{
                                    ...LayoutSlotStyles.podsTitle
                                }}
                            >
                                {props.title}
                            </div>
                        </div>
                    )}
                    {type == "contact" && (
                        <div>
                            <div
                                style={{
                                    ...LayoutSlotStyles.contactsTitle
                                }}
                            >
                                {props.title}
                            </div>
                        </div>
                    )}
                    {type == "editorial" && (
                        <div>
                            <div
                                style={{
                                    ...LayoutSlotStyles.editorialTitle
                                }}
                            >
                                {props.title}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {type == "editorial" && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>{props.title}</Tooltip>}>
                            <div
                                style={{
                                    ...LayoutSlotStyles.editorialTitle,
                                    ...LayoutSlotStyles.editorialTitleOverflow
                                }}
                            >
                                {props.title}
                            </div>
                        </OverlayTrigger>
                    )}
                    {type == "contact" && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>{props.title}</Tooltip>}>
                            <div
                                style={{
                                    ...LayoutSlotStyles.contactsTitle,
                                    ...LayoutSlotStyles.editorialTitleOverflow
                                }}
                            >
                                {props.title}
                            </div>
                        </OverlayTrigger>
                    )}
                    {type == "pod" && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>{props.title}</Tooltip>}>
                            <div
                                style={{
                                    ...LayoutSlotStyles.podsTitle,
                                    ...LayoutSlotStyles.editorialTitleOverflow
                                }}
                            >
                                {props.title}
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
            )}
        </div>
    );
}

export default Pages_LayoutTitle;
